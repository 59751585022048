import { App } from "vue";
import { Router } from "vue-router";
import * as Sentry from "@sentry/vue";
import { Options, BrowserClientReplayOptions, Integration } from "@sentry/core";
import pinia from "@/vue/store/index";

const { customer, IS_STAGING } = useTheme();

interface LoadSentryOptions {
  app: App;
  appName: string;
  settings?: any;
  router?: Router;
}

const loadOptions = (
  app: App,
  settings?: any,
  router?: Router
): Options & BrowserClientReplayOptions => {
  const integrations: Integration[] = [Sentry.replayIntegration()];

  if (router) {
    integrations.push(
      Sentry.browserTracingIntegration({ router, routeLabel: "path" })
    );
  }

  return {
    app,
    dsn: IS_STAGING
      ? import.meta.env.VITE_STAGING_SENTRY_DSN
      : import.meta.env.VITE_SENTRY_DSN,
    environment: IS_STAGING ? "staging" : "production",
    integrations,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event) => {
      if (customer) {
        event.user = { id: customer.id, email: customer.email };
      }
      return event;
    },
    ...settings,
  };
};

const loadSentry = ({ app, appName, settings, router }: LoadSentryOptions) => {
  Sentry.setContext("app", { name: appName });
  Sentry.init(loadOptions(app, settings, router));
  pinia.use(Sentry.createSentryPiniaPlugin());
};

export { loadSentry };
